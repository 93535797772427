<template>
  <div>
    <van-dropdown-menu style="z-index: 1000000;
    position: fixed;
    top: 0;
    width: 100%;">
      <van-dropdown-item v-model="value1" :options="option1" @change="onTo"/>
      <van-dropdown-item v-model="value2" :options="option2" @change="onTo"/>
    </van-dropdown-menu>


    <baidu-map class="map" :center="center" @ready="handler" :zoom="15" >
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true" ></bm-geolocation>
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-navigation>
      <bm-marker v-for="(item,index) in list" :key="index"  :position="{lng:item.lng, lat: item.lat}" @click="openDia(item,true)" :dragging="true" >
        <bm-info-window :show="item.show" @close="openDia(item,false)" @open="openDia(item,true)">{{item.address}}</bm-info-window>
      </bm-marker>
    </baidu-map>
  </div>
</template>

<script>
import axios from 'axios'
const requests = axios.create({
  baseURL: "https://ebike.api.51miaomeng.com", // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
  // baseURL: 'http://168.192.0.123',
  timeout: 6000 // 请求超时时间
})
export default {
  components: {
  },

  data() {
    return {
      screenHeight: document.body.clientHeight + 'px',
      center:'上海市闵行区',
      value1:"当前日期",
      value2:"徐汇区",
      BMap:null,
      map:null,
      list:[],
      crawler:null,
      ss:null,
      option1 : [
        { text: '当前日期', value: "当前日期" },
      ],
      option2 : [
        { text: '闵行区', value: "闵行区" },
      ]

    };
  },
  watch:{
    // "value2":function (value){
    //   this.onTo(value,this.value1);
    // },
    // "value1":function (value){
    //   this.onTo(this.value2,value);
    // }
  },
  computed: {

  },
  created() {
    this.getDate()
  },
  methods: {
    getDate(){
      requests.get('jshERP-boot/sms/yq/date').then(res=>{
        let data = res.data.data;
        this.option1 = data.map(item=>{
          return { text: item, value: item };
        })
        this.value1 = this.option1[0]["value"]


        requests.get('jshERP-boot/sms/yq/regions').then(res=>{
          let data = res.data.data;
          this.option2 = data.map(item=>{
            return { text: item, value: item };
          })
          this.value2 = this.option2[0]["value"]
          this.onTo()
        })
      })
    },



    openDia(item,f){
      item.show = f
      this.$forceUpdate()
    },
    onTo(){
      this.center = "上海市"+this.value2
      this.onData();
    },


    handler({ BMap,map }) {
      this.BMap = BMap
      this.map = map
      let vm = this
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r){
        window.console.log(r)
        vm.ss = JSON.stringify(r)
        let district = r.district;
        if (district!=null || district!=''){
          this.onTo(district)
        }
        map.panTo(r.point);
      });
      this.onData();
    },

    onData(){
      let vm = this;
      requests.get('jshERP-boot/sms/yq/info',{params:{region:this.value2,startPeriod:this.value1 ,endPeriod:this.value1}}).then(res=>{
        let data = res.data.data;
        this.list = []
        for (let i = 0; i < data.length; i++) {
            let datum = data[i];
            vm.onMapClick(datum);
        }
      })
    },

    onMapClick(datum) {
      let vm = this
      const myGeo = new this.BMap.Geocoder()
      myGeo.getPoint("上海市"+this.value2+datum, function(point){
        if(point){
          point["address"] = datum
          point["show"] = false
          vm.list.push(point)
        }else{
          alert('您选择的地址没有解析到结果！');
        }
      }, '上海市')
    }
  }
};
</script>
<style>
/* The container of BaiduMap must be set width & height. */
.map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  position: fixed;
  top: 0;
}
</style>
